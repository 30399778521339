import cn from 'classnames';
import { values } from 'lodash';
import { FC, HTMLProps, MouseEvent, ReactNode, forwardRef } from 'react';
import { Spinner } from '..';
import { ButtonSize, ButtonType, ButtonVariant } from '../enums/button';
import { ButtonModifier } from '../types/button';

interface ButtonIconProps extends HTMLProps<HTMLButtonElement> {
  variant?: ButtonVariant;
  icon?: ReactNode;
  isLoading?: boolean;
  children?: ReactNode;
  className?: string;
  classNameIcon?: string;
  disabled?: boolean;
  disabledToolTip?: string;
  type?: ButtonType;
  buttonSize?: ButtonSize;
  noficiationCount?: number;
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const ButtonIcon: FC<Omit<ButtonIconProps, 'ref'>> = forwardRef<
  HTMLButtonElement,
  ButtonIconProps
>(
  (
    {
      variant = ButtonVariant.PRIMARY,
      icon,
      isLoading = false,
      children,
      className,
      classNameIcon,
      disabled = false,
      disabledToolTip,
      type = ButtonType.BUTTON,
      buttonSize = ButtonSize.NORMAL,
      noficiationCount = null,
      handleClick,
      ...htmlProps
    },
    ref,
  ) => {
    const variantDefaultClasses: Record<ButtonVariant, string> = {
      primary: 'bg-primary-cta text-on-primary shadow-sm shadow-background',
      secondary: 'bg-background text-on-background-dimmed shadow-sm shadow-background',
      tertiary: 'border border-on-background-main !text-on-background-main',
      danger: 'bg-error-light text-on-error-dark shadow-sm shadow-background',
      shape: 'relative border-none rounded-none p-4 text-2xs font-bold',
    };

    const variantModifierClasses: Record<ButtonVariant, ButtonModifier> = {
      [ButtonVariant.PRIMARY]: {
        hover:
          'hover:bg-primary-variant hover:text-on-primary group-hover/button:bg-primary-variant group-hover/button:text-on-primary',
        disabled: 'disabled:bg-control disabled:text-on-control-dimmed',
      },
      [ButtonVariant.SECONDARY]: {
        hover:
          'hover:bg-background-variant hover:text-on-background-main group-hover/button:bg-background-variant group-hover/button:text-on-background-main',
        disabled: 'disabled:bg-control disabled:text-on-control-dimmed',
      },
      [ButtonVariant.TERTIARY]: {
        hover: 'hover:bg-background-variant group-hover/button:bg-background-variant',
        disabled:
          'disabled:border disabled:!border-on-control-dimmed disabled:!text-on-control-dimmed',
      },
      [ButtonVariant.DANGER]: {
        hover: 'hover:bg-error-dark',
        disabled: 'disabled:bg-control disabled:text-on-background-dimmed',
      },
      [ButtonVariant.SHAPE]: {
        hover: 'hover:text-on-secondary',
        disabled: '',
      },
    };

    const commonClassNames = cn({
      'relative transition duration-300 inline-flex px-4 items-center justify-center rounded-md group/button min-w-[32px] subtitle-2 px-2.5 focus:ring-1 focus:rounded-md ring-primary-cta ring-inset ring-offset-0 ring-inset-none':
        true,
      ' h-10 py-2': buttonSize === ButtonSize.NORMAL,
      ' h-8 py-[6px]': buttonSize === ButtonSize.SMALL,
    });
    const modifierClasses = values(variantModifierClasses[variant]).join(' ');
    const defaultClasses = variantDefaultClasses[variant];

    const classnames = cn({
      '!cursor-wait': isLoading,
      'cursor-not-allowed': disabled,
      'w-10 h-10': !children && icon && buttonSize === ButtonSize.NORMAL,
      'w-8 h-8': !children && icon && buttonSize === ButtonSize.SMALL,
      [defaultClasses]: true,
      [commonClassNames]: true,
      [modifierClasses]: true,
      [`${className}`]: true,
      [`${classNameIcon}`]: true,
    });

    return (
      <button
        ref={ref}
        className={classnames}
        onClick={handleClick}
        disabled={isLoading || disabled}
        type={type}
        {...htmlProps}
      >
        {/* Custom Icon */}
        {icon && (
          <div
            className={cn({
              'mr-2': !!children,
              'opacity-0': !children && isLoading,
              'pointer-events-none': true,
            })}
          >
            {icon}
          </div>
        )}
        {children && (
          <span
            className={cn({
              'z-1 inline-flex items-center truncate transition-opacity':
                variant === ButtonVariant.SHAPE,
              'opacity-0': isLoading,
              'pointer-events-none': true,
            })}
          >
            {children}
          </span>
        )}

        {/* Loading Icon */}
        <div
          className={cn({
            'absolute left-0 top-0 h-full w-full items-center justify-center opacity-75': true,
            hidden: !isLoading,
          })}
        >
          <Spinner size={16} wrapperClasses="flex items-center justify-center h-full" />
        </div>
        {disabled && disabledToolTip && (
          <div className="shadow-tooltip subtitle-2 z-1 !bg-secondary !text-on-secondary pointer-events-none absolute right-0 top-[44px] w-full min-w-[220px] whitespace-normal rounded-lg p-4 text-left font-normal opacity-0 transition-all group-hover/button:opacity-100">
            {disabledToolTip}
          </div>
        )}

        {!!noficiationCount && (
          <div className="bg-error-light text-on-error-light absolute -end-1 -top-1 inline-flex h-4 w-4 items-center justify-center rounded-full text-xs font-bold">
            {noficiationCount}
          </div>
        )}
      </button>
    );
  },
);

ButtonIcon.displayName = 'ButtonIcon';
